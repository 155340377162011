import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";

import Card from "components/Card/Card";
import { iconsArray } from "variables/Variables.jsx";

class Prediction extends Component {
  render() {
    return (
      <div className="content commentWrap">
        <Grid fluid>
          <Row>
            <Col md={12}>
            <Card
                title="Machine Learning Prediction"
                ctAllIcons
                category={
                  "Under construction and coming soon"
                }
                content={
                  <div>
                  <p>
                    I am still unsure about what data to collect to predict processing timeline, while also waiting on collecting enough data.
                  </p>
                  <p>
                    Please feel free to leave a comment or advice on the discussion board.
                  </p>
                  <p>
                    Stay tuned!
                  </p>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Prediction;
