import React, { Component } from "react";
import { FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";

import axios from "axios";

class FormInput extends React.Component {
  constructor(props, context) {
    super(props, context);

    // this.handleChange = this.handleChange.bind(this);

    this.state = {
      nickname: '',
      comment: '',
    };
  }

  handleNicknameChange = (e) => {
    this.setState({ nickname: e.target.value });
  }

  handleCommentChange = (e) => {
    this.setState({ comment: e.target.value});
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.nickname.length == 0 || this.state.comment.length==0) {
      this.props.handleClick("tc", "Nickname and comment are required", true)
    }
    let payload = {
      "approved": "true",
      "name": this.state.nickname,
      "content": this.state.comment,
      "timeCommented": Math.floor(Date.now()/1000),
    }

    this.props.updateComments(payload);
    // make  axios call -put method
    let commentPostUrl = `https://7bkx7sep38.execute-api.us-west-2.amazonaws.com/prod/comments`;
    axios.post(commentPostUrl, payload)
      .then(res => {
        if (res.data.result) {
          this.props.handleClick("tc", "Comment sumbitted successfully", false);
        } else {
          this.props.handleClick("tc", "Something went wrong, please try again later", true);
        }
      })
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup
          controlId="formBasicText"
        >
          <ControlLabel>Nickname</ControlLabel>
          <FormControl
            className="inputBox"
            type="text"
            value={this.state.nickname}
            placeholder="GitDone"
            onChange={this.handleNicknameChange}
          />
          <ControlLabel>Comment</ControlLabel>
          <FormControl
            className="inputBox"
            type="text"
            value={this.state.comment}
            placeholder="Am I a comment?"
            onChange={this.handleCommentChange}
          />
          <Button type="submit" className="submitButton" >Submit</Button>
          <FormControl.Feedback />
        </FormGroup>
      </form>
    );
  }
}

export default FormInput;