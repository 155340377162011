import Dashboard from "views/Dashboard.jsx";
import DiscussionBoard from "views/DiscussionBoard.jsx";
import About from "views/About.jsx";
import Prediction from "views/Prediction.jsx";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    layout: "/admin"
  },
  {
    path: "/discussion",
    name: "Discussion Board",
    icon: "pe-7s-user",
    component: DiscussionBoard,
    layout: "/admin"
  },
  {
    path: "/about",
    name: "About",
    icon: "pe-7s-note2",
    component: About,
    layout: "/admin"
  },
  {
    path: "/prediction",
    name: "Prediction",
    icon: "pe-7s-science",
    component: Prediction,
    layout: "/admin"
  },

];

export default dashboardRoutes;
