import React, { Component } from 'react';
import { Button, Comment, Form, Header } from 'semantic-ui-react'
import { Grid, Row, Col } from "react-bootstrap";
import Card from "components/Card/Card";
import axios from "axios";
import moment from "moment";
import FormInputs from "components/FormInputs/FormInputs";

const userIcons = [<i className="fas fa-basketball-ball"></i>, <i className="fas fa-bell"></i>, <i className="fas fa-biking"></i>, <i className="fas fa-bolt"></i>, <i className="fas fa-book-reader"></i>, <i className="fas fa-burn"></i>, <i className="fas fa-cannabis"></i>, <i className="fas fa-capsules"></i>, <i className="fas fa-cat"></i>, <i className="fas fa-carrot"></i>, <i className="fas fa-chess-queen"></i>, <i className="fas fa-chess-knight"></i>, <i className="fas fa-chess-bishop"></i>, <i className="fas fa-dog"></i>, <i className="fas fa-dove"></i>, <i className="fas fa-drafting-compass"></i>]
const colors = ["fff", "#E1C2A5", "	#E57C67", "#F0E071", "#DFA94F", "#7CCABC", "#A8E0D4"]

class DiscussionBoard extends Component {
  state = {
    comments: null,
    isLoading: true,
  }

  componentDidMount() {
    const url = "https://7bkx7sep38.execute-api.us-west-2.amazonaws.com/prod/"
    axios.get(url + "comments")
      .then(res => {
        const comments = res.data.reverse();
        this.setState({ comments });
        this.setState({isLoading: false});
      })
  }

  updateComments = (newComment) => {
    let updatedComments = [...this.state.comments];
    updatedComments.unshift(newComment);
    this.setState({comments: updatedComments});
  }

  render() {
    let commentComponent;
    if (this.state.isLoading) {
      commentComponent = <div className="loading"></div>
    } else {
    commentComponent = this.state.comments.map((item, idx) => {
      let iconClass = userIcons[Math.floor(Math.random() * userIcons.length)];
      let icon = <span style={{fontSize: "3em", color: colors[Math.floor(Math.random() * colors.length)]}}>
                    {iconClass}
                  </span>
      return (
        <div key={idx} className="commentBox">
          <Grid>
            <Row>
              <Col md={1}>
                <div className="commentUserIcon" >
                {icon}
                </div>
              </Col>
              <Col md={6}>
                <Row className="userNameCommentBox">
                  <Col md={11}>
                  <p >{item.name}<span className="commentBoxTimeStamp">{moment(moment.unix(item.timeCommented)).fromNow()}</span></p>               
                  </Col>
                  </Row>
                <Row>
                <Col md={11}>
                  <p className="contentPara">{item.content}</p>
                  </Col>
                  </Row>  
              </Col>
            </Row>
          </Grid>
        </div>
      )
    })
  }
    return (
      <div className="content commentWrap">
        <Grid fluid>
          <Row>
          <Col md={4}>
              <Card
                title="Leave a comment"
                ctAllIcons
                content={<FormInputs updateComments={this.updateComments} handleClick={this.props.handleClick} ncols={1} label="h"/>}
              />
            </Col>
            <Col md={8}>
              <Card
                title="Discussion Board"
                ctAllIcons
                content={commentComponent}
              />
            </Col>
          </Row>
        </Grid>
      </div>
    )
  }
}
export default DiscussionBoard;
