import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { BarGraph } from "components/BarGraph/BarGraph.jsx";
import { PieChart } from "components/PieChart/PieChart.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";


import moment from 'moment';

class Dashboard extends Component {
  state = {
    isLoading: true,
    caseDistributionUpdatedTimeAgo: null,
    distributionPer1000Data: null,
    caseDistributionSummary: null,
    summaryUpdateTimeAgo: null,

    summaryIsLoading: true,
    summaryDistributionData: null,
    summaryCaseStart: null,
    summaryCaseEnd: null,

    updateStatusIsLoading: true,
    updateStatusData: null,
    updateStatusUpdatedTImeAgo: "loading...",

    totalCardBeingProduced: "loading...",
  }

  componentDidMount() {
    let allCaseDistribution = window.allCaseDistribution;
    let summaryDistribution = window.summaryDistribution;
    let updateCaseDistribution = window.updateCaseDistribution;
    this.setState({distributionPer1000Data: allCaseDistribution});
    this.setState({summaryDistributionData: summaryDistribution});
    this.setState({updateStatusData: updateCaseDistribution});

   
    
    this.setState({caseDistributionUpdatedTimeAgo: moment(moment.unix(allCaseDistribution[0]["uploadTimeStamp"])).fromNow()});
    this.setState({summaryUpdateTimeAgo: moment(moment.unix(summaryDistribution["uploadTimeStamp"])).fromNow()});
    this.setState({updateStatusUpdatedTImeAgo:  moment(moment.unix(updateCaseDistribution[0]["uploadTimeStamp"])).fromNow()});

    this.setState({isLoading: false});
    this.setState({summaryIsLoading: false});
    this.setState({updateStatusIsLoading: false});

    let count = 0;
    for (let i = 0; i < updateCaseDistribution.length; i++) {
      count += updateCaseDistribution[i]["New New Card Is Being Produced"]
    }
    this.setState({totalCardBeingProduced: count});
    // const url = "https://7bkx7sep38.execute-api.us-west-2.amazonaws.com/prod/"
    // axios.get(url + "status-distribution")
    //   .then(res => {
    //     const distributionPer1000Data = res.data.body;
    //     const updateTime = JSON.parse(distributionPer1000Data)[0].uploadTimeStamp;
    //     this.setState({ caseDistributionUpdatedTimeAgo: moment(moment.unix(updateTime)).fromNow()})
    //     this.setState({ distributionPer1000Data: JSON.parse(distributionPer1000Data).slice(0, 60) });
    //     this.setState({isLoading: false});
    //   })
    //   axios.get(url + "status-summary")
    //   .then(res => {
    //     const distributionSummary = res.data.body;
    //     const summaryUpdateTime = JSON.parse(distributionSummary)[0].uploadTimeStamp;
    //     this.setState({ summaryUpdateTimeAgo: moment(moment.unix(summaryUpdateTime)).fromNow()})
    //     this.setState({ summaryDistributionData: JSON.parse(distributionSummary)[0] });
    //     this.setState({summaryCaseStart: JSON.parse(distributionSummary)[0].startRange});
    //     this.setState({summaryCaseEnd: JSON.parse(distributionSummary)[0].endRange});
    //     this.setState({summaryIsLoading: false});
    //   })
    //   axios.get(url + "status-update")
    //   .then(res => {
    //     const updateStatusData = res.data;
    //     const updateTime = updateStatusData[0].uploadTimeStamp;
    //     this.setState({ updateStatusUpdatedTImeAgo: moment(moment.unix(updateTime)).fromNow()})
    //     this.setState({ updateStatusData: updateStatusData.slice(1, 43) });
    //     this.setState({updateStatusIsLoading: false});

    //     //calculate how many totalCardBeingProduced
    //     
    //   })
  }

  createLegend(json) {
    var legend = [];
    for (var i = 0; i < json["names"].length; i++) {
      var type = "fa fa-circle text-" + json["types"][i];
      legend.push(<i className={type} key={i} />);
      legend.push(" ");
      legend.push(json["names"][i]);
    }
    return legend;
  }
  
  render() {
    let barGraphRender;
    if (this.state.isLoading) {
      barGraphRender = <div className="loading"></div>
    } else {
      barGraphRender =  <BarGraph 
          updateTime={this.state.updateTime}
          data={this.state.distributionPer1000Data}
          keys={[ "Case Was Received", "Case Was Rejected", "Card Successfully Produced And Sent To Applicant", "Case Was Approved", "Others", "New Card Is Being Produced", "Request For Evidence"]}
        />
    }

    let pieChartRender;
    if (this.state.summaryIsLoading) {
      pieChartRender = <div className="loading"></div>
    } else {
      pieChartRender =  <PieChart 
          summaryIsLoading={this.state.summaryIsLoading} 
          summaryDistributionData={this.state.summaryDistributionData}
        />
    }

    let lineChartRender;
    if (this.state.updateStatusIsLoading) {
      lineChartRender = <div className="loading"></div>
    } else {
      lineChartRender =  <BarGraph 
          updateStatusIsLoading={this.state.updateStatusIsLoading} 
          updateTime={this.state.updateStatusUpdatedTImeAgo}
          data={this.state.updateStatusData}
          keys={[ "New Case Was Received", "New Case Was Rejected", "New Card Successfully Produced And Sent To Applicant", "New Case Was Approved", "New Others", "New New Card Is Being Produced", "New Request For Evidence"]}
        />
    }

    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-exapnd2 text-warning" />}
                statsText="Average Processing Wait"
                statsValue="TBD"
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Updated now"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-wallet text-success" />}
                statsText="Current Daily Processing Count"
                statsValue={this.state.totalCardBeingProduced}
                statsIcon={<i className="fa fa-calendar-o" />}
                statsIconText={`Updated ` + this.state.updateStatusUpdatedTImeAgo}
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-graph1 text-danger" />}
                statsText="ML Prediction on your case"
                statsValue="NA"
                statsIcon={<i className="fa fa-clock-o" />}
                statsIconText="In the last hour"
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-notebook text-info" />}
                statsText="Website visit TD"
                statsValue="1903"
                statsIcon={<i className="fa fa-refresh" />}
                statsIconText="Updated now"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                statsIcon="fa fa-history"
                id="chartHours"
                title="Case Status"
                category="based on per 1000 distribution"
                stats={`Updated ` + this.state.caseDistributionUpdatedTimeAgo}
                content={
                  <div className="barGraph">
                    {barGraphRender}
                  </div>
                }
              />
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <Card
                id="chartActivity"
                title="Case Distribution Summary"
                category={`from  YSC${this.state.summaryCaseStart} to YSC${this.state.summaryCaseEnd}`}
                stats={`Updated ` + this.state.summaryUpdateTimeAgo}
                statsIcon="fa fa-history"
                content={
                  <div className="pieChart">
                    {pieChartRender}
                  </div>
                }
              />
            </Col>

            <Col md={8}>
              <Card
                title="Case Status Updated Yesterday - under construction"
                category="Distribution per 1000 aggregation"
                stats={`Updated ` + this.state.updateStatusUpdatedTImeAgo}
                statsIcon="fa fa-history"
                content={
                  <div className="updateStatueChart">
                    {lineChartRender}
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
