import React, { Component } from "react";
import { NavItem, Nav, NavDropdown, MenuItem } from "react-bootstrap";
import { NavLink } from "react-router-dom";

class AdminNavbarLinks extends Component {
  render() {
    const notification = (
      <div>
        <i className="fa fa-globe" />
        <b className="caret" />
        <span className="notification">2</span>
        <p className="hidden-lg hidden-md">Notification</p>
      </div>
    );
    return (
      <div>
        {/* <Nav>
          <NavItem eventKey={1} href="#">
            <i className="fa fa-dashboard" />
            <p className="hidden-lg hidden-md">Dashboard</p>
          </NavItem>
          <NavDropdown
            eventKey={2}
            // title={notification}
            noCaret
            id="basic-nav-dropdown"
          >
            <MenuItem eventKey={2.1}>Notification 1 TBD</MenuItem>
            <MenuItem eventKey={2.2}>Notification 2 TBD</MenuItem>
          </NavDropdown>
          <NavItem eventKey={3} href="#">
            <i className="fa fa-search" />
            <p className="hidden-lg hidden-md">Search</p>
          </NavItem>
        </Nav> */}
        <Nav pullRight>
            <NavItem href="/admin/discussion">
              <i className="fas fa-users"></i>  &nbsp; Discussion Board 
            </NavItem>
          <NavItem href="/admin/about">
          <i className="fas fa-poo"></i> &nbsp; About
          </NavItem>

          <NavItem href="/admin/prediction">
          <i className="fas fa-user-astronaut"></i>  &nbsp; Prediction
          </NavItem>
          
        </Nav>
      </div>
    );
  }
}

export default AdminNavbarLinks;
