import React, { Component } from "react";
import { Grid, Row, Col } from "react-bootstrap";

import Card from "components/Card/Card";
import { iconsArray } from "variables/Variables.jsx";

class About extends Component {
  render() {
    return (
      <div className="content commentWrap">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title="About this project"
                ctAllIcons
                category={
                  "Why I made this & what tech stacks are used"
                }
                content={
                  <div>
                    <p>
                      * This website is a work in progress as you probably can tell. Feel free to leave comments/advice on features you would like. 
                    </p>

                  <p>
                    I made this project after months of waiting on my OPT. I hope the information provided here can help ease the anxiety of waiting for other people as well. After all, you are not alone. :) Vent on the discussion board. 
                  </p>
                  <p className="baldPara">Tech Stack</p>
                  <p>
                    Front-end: React, CloudFront
                  </p>
                  <p>
                    Back-end: AWS CDK (StepFunctions, Lambda, DynamDB, API Gateway), Glue, PySpark
                  </p>
                  <p className="disclaimer">
                      * Disclaimer: This is a student project and for educational purpose only. There is no guarantee on the accuracy of the information. Please use at your own risk. This project uses 100% AWS resource and hence not free. There may be a discontinuation of this service if cost becomes an issue in the future. I reserve the right to alter or shut down this service. 
                    </p>
                  </div>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card
                title="FAQ"
                ctAllIcons
                category={
                  "Constantly updating based on feedback"
                }
                content={
                  <div>
                  <p className="baldPara">Does all charts only consider I-765 type?</p>
                  <p>
                    Yes. All other types are excluded.
                  </p>
                  <p className="baldPara">What status types are included in "Others" category?</p>
                  <p>
                  [Fees Were Waived]
[Decision Notice Mailed][Notice Explaining USCIS Actions Was Mailed]
[Duplicate Notice Was Mailed]
[Date of Birth Was Updated][Case Was Reopened][Name Was Updated]
[Fingerprint Review Was Completed]
[Fees Were Waived]
[Decision Notice Mailed]



                  </p>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default About;
